.second {
  animation-delay: 0.33s;
}

.third {
  animation-delay: 0.66s;
}

.first,
.second,
.third {
  animation-name: bump;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  display: inline-block;
}

@keyframes bump {
  0%,
  100% {
    transform: initial;
  }

  50% {
    transform: translateY(-3px);
  }
}
