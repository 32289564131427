@import "styles/variables.scss";

.logoContainer,
.logoContainer > a {
  display: flex;
  align-items: center;
}

.logo {
  height: 28px;
  margin-right: 20px;
}

.logoText {
  height: 15px;
}
