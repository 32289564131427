@import "styles/variables.scss";

.container {
  display: inline-block;
  vertical-align: top;
  padding: $body-padding;
  padding-bottom: calc(2 * #{$body-padding});

  h3 {
    font-size: 48px;
    font-weight: bold;
    color: $dark-blue;
    margin-bottom: 24px;

    @media screen and (max-width: $mobile-max-width) {
      font-size: 36px;
      max-width: 200px;
    }
  }

  p {
    margin: 24px 0;
  }

  a:not(:global(.button)) {
    font-size: 18px;
    text-decoration: none;
    padding-bottom: 4px;
    border-bottom: 2px solid $red;
  }
}

.inner {
  margin: auto;
  max-width: 250px;
}
