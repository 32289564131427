@import "styles/variables.scss";

.modal {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  padding: 18px 24px;

  > button {
    // hiding the default modal close button and replacing
    // as its styles are so opinionated it's easier to start from scratch
    display: none;
  }

  h1 {
    color: $dark-blue;
    font-size: 16px;
    font-weight: bold;
  }

  @media screen and (max-width: $mobile-max-width) {
    max-width: 100vw;
    height: 100vh;
  }
}

.saveSearchForm {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 12px;

  input {
    font-size: 14px;
    height: 32px;
    width: 351px;
  }

  .buttonContainer {
    display: flex;
    justify-content: flex-end;

    .closeButton {
      color: $gray;
      background-color: $white;
    }

    .saveSearchButton {
      margin-left: 24px;
    }
  }
}
