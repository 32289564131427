@import "styles/variables.scss";

.container {
  position: relative;
  z-index: $z-header;
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  color: $black;
  padding: 20px $body-padding;

  a:hover,
  button:hover,
  button:focus {
    transform: none;
    text-decoration: none;
    box-shadow: none;
    outline: none;
    cursor: pointer;
  }

  a,
  button {
    color: $black;
    display: flex;
  }

  .phoneButton,
  .cogButton,
  .burgerButton,
  .closeButton {
    margin-left: 12px;
    width: 16px;
    height: 16px;

    &:hover {
      cursor: pointer;
      color: $red;
    }
  }

  h1 {
    font-size: 24px;
    letter-spacing: -0.5px;
    margin-bottom: 0;
  }

  .navigationContainer {
    display: flex;
    align-items: center;
  }

  .phoneButton :global(.icon-phone) {
    font-size: 16px;
    vertical-align: bottom;
  }

  .contact {
    a {
      text-decoration: none;

      :global(.icon-phone) {
        margin-right: 5px;
        width: 16px;
        height: 16px;
      }
    }
  }

  .toggleButton {
    display: none;

    ~ label,
    ~ .phoneButton {
      display: none;
      font-size: 18px;
    }
  }

  .nav {
    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    li {
      display: flex;
      justify-content: center;
      position: relative;

      &.contact {
        padding: 0;

        ~ a,
        span {
          padding: 0;
        }
      }
    }

    a.cogButton {
      margin-left: 12px;
      padding: 0;

      span.iconCog {
        font-size: 125%;
        padding: 0;
      }
    }

    a,
    span,
    button {
      font-size: inherit;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: 12px;
      margin: 0;
      background-color: transparent;
      text-transform: none;
      font-weight: normal;
    }

    .quickStart {
      display: none;
    }
  }

  @media screen and (max-width: $mobile-max-width) {
    padding: 20px $body-padding-mobile 16px $body-padding-mobile;
  }

  @media screen and (max-width: $mobile-max-width) {
    .toggleButton {
      &:not(:checked) {
        ~ .nav {
          button,
          a:first-child {
            display: none;
          }
        }

        ~ .burgerButton,
        ~ .cogButton,
        ~ .phoneButton {
          display: block;
        }
      }

      &:checked {
        ~ .nav {
          flex-direction: column;
          z-index: 2;
          position: fixed;
          top: 58px;
          height: calc(100vh - 58px);
          left: 0;
          right: 0;
          margin: 0;
          background-color: $white;

          ul {
            flex-direction: column;
            width: 100%;
            height: 100%;
          }

          li {
            width: 100%;
            background-color: $white;
          }

          a,
          button {
            display: block;
            width: 100%;
            height: 100%;
            font-size: 24px;
            padding: 16px;

            &:hover {
              background-color: $light-blue;
            }
          }
        }

        ~ .nav .contact,
        ~ .burgerButton,
        ~ .cogButton,
        ~ .phoneButton {
          display: none;
        }

        ~ .nav .quickStart,
        ~ .closeButton {
          display: block;
        }
      }
    }
  }
}
