@import "styles/variables.scss";

.modal {
  max-width: 940px;
  max-height: 501px;
  width: 100%;
  display: flex;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);

  > button {
    // hiding the default modal close button and replacing
    // as its styles are so opinionated it's easier to start from scratch
    display: none;
  }

  @media screen and (max-width: $mobile-max-width) {
    max-width: initial;
    padding: 48px;
    height: initial;
  }
}

.button {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 24px;
  border-radius: 0 2px 2px 0;
  line-height: normal;
  margin: 0;

  @media screen and (max-width: $mobile-max-width) {
    border-radius: 0 0 2px 2px;
  }
}

.sideContent {
  width: 282px;

  @media screen and (max-width: $mobile-max-width) {
    display: none;
  }
}

.mainContent {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.container {
  background-color: rgba(255, 255, 255, 0.8);

  @media screen and (max-width: $mobile-max-width) {
    display: flex;
    align-items: center;
    padding: 0 12px;
  }
}

.closeButton {
  color: $secondary-dgray;
  background-color: transparent;
  border: none;
  position: absolute;
  top: -26px;
  right: 0;
  padding: 0;
  font-size: 19px;

  &:hover {
    color: $red;
    cursor: pointer;
    text-decoration: none;
  }
}

.contentWrapper {
  width: 100%;
  display: inherit;
}

.image {
  height: 100%;
}
