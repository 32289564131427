@import "styles/variables.scss";

.form {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: $desktop-min-width) {
    width: 340px;
  }
}

.input {
  font-size: 16px !important;
  padding: 10px 12px !important;
  margin-bottom: 12px !important;
  margin-right: 0 !important;
}

.button {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 24px;
  border-radius: 2px;
  background-color: $red;
  color: $white;
  margin-bottom: 5px;
  border: none;
  cursor: pointer;

  &:disabled {
    background: $gray;
  }
}

.sideContent {
  width: 282px;
  height: 501px;
  background-size: cover;
  background-position: center;
  color: $white;
  font-size: 20px;
  display: block;
}

.heading {
  font-size: 20px;
  line-height: normal;
  color: $black;
  margin-bottom: 6px;
}

.subheading {
  font-size: 16px;
  font-weight: normal;
  line-height: normal;
  color: $black;
  margin-bottom: 24px;
}

.error {
  color: $red;
  line-height: normal;
  margin: 12px 0;
  visibility: hidden;
}

.inputError {
  border-color: $red !important;
}

.visible {
  visibility: visible;
}

.createAccountLinkContainer {
  text-align: center;
  margin-top: 0.75em;
}

.cta {
  max-width: 340px;
}

.customSideContent {
  height: 100%;
  width: 100%;
}

.image {
  height: 100%;
}
