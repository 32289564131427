@import "styles/variables";

// basicInner
// inner
// container
// closeButton

@keyframes open {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  z-index: $z-modal;
  background-color: rgba($secondary-black, 0.5);

  @media screen and (min-width: $desktop-min-width) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.basicInner {
  position: relative;
  background-color: $white;
  animation: open 0.16s ease-out 1;
  box-shadow: 0 4px 15px -5px $secondary-dgray;

  .closeButton {
    top: 20px;
    right: 20px;
    font-size: 16px;
    padding: 4px 12px;
    position: absolute;
    color: $secondary-dgray;
    border: 1px solid $off-white;
    background-color: transparent;

    &:hover {
      background-color: $off-white;
    }
  }

  @media screen and (max-width: $mobile-max-width) {
    width: 100%;
    height: 100%;
  }
}

.inner {
  min-height: 100%;
  overflow-y: auto;
  max-height: 100vh;
  position: relative;
  color: $secondary-black;
  background-color: $white;
  padding: $body-padding-mobile;
  animation: open 0.16s ease-out 1;
  box-shadow: 0 4px 15px -5px $secondary-dgray;

  input:last-child {
    margin-bottom: 0;
  }

  input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]),
  :global(.select) {
    width: 100%;
    display: block;
    margin: 12px 0;
  }

  p {
    margin: 16px 0;
  }

  .closeButton {
    top: 20px;
    right: 20px;
    padding: 4px 12px;
    position: absolute;
    font-size: 16px;
    color: $secondary-dgray;
    border: 1px solid $off-white;
    background-color: transparent;

    &:hover {
      background-color: $off-white;
    }
  }

  @media screen and (max-width: $mobile-max-width) {
    padding-top: 40px;
  }

  @media screen and (min-width: $desktop-min-width) {
    width: 50%;
    min-height: auto;
    border-radius: 3px;
    padding: $body-padding;
  }
}

// :global(.has-modal) {
//   height: auto;
//   background-color: $secondary-dgray;
//   :global(#app) {
//     filter: blur(5px);
//   }
// }
