@import "styles/variables.scss";

.container {
  position: relative;
  z-index: $z-footer;
  font-size: 14px;

  p {
    line-height: 2;
  }

  h2,
  h3 {
    text-transform: uppercase;
  }

  a {
    color: $black;
  }

  .upper ul,
  .lower .lists {
    list-style: none;
    margin-bottom: 28px;
  }

  .responsiveFlex {
    display: flex;
    flex-direction: column;

    @media screen and (min-width: $desktop-min-width) {
      flex-direction: row;
    }
  }

  .upper {
    background-color: $white;

    h2 {
      text-transform: uppercase;
      margin-bottom: 16px;
      color: $black;
      font-style: inherit;
      line-height: 1.9;
      font-family: $heading-font;
      font-weight: bold;
      font-size: 14px;
      letter-spacing: normal;
    }

    .innerContainerCol2 {
      display: flex;
      flex: 2;

      .item {
        flex: 1;

        li {
          padding: 0.25rem 0;
        }

        @media screen and (max-width: $mobile-max-width) {
          width: 50%;
        }
      }
    }

    .innerContainerCol1 {
      flex: 1;
    }

    .address {
      line-height: 1.5;
    }

    .socialMedia {
      margin-top: 16px;

      li {
        display: inline-block;
        vertical-align: middle;

        &:not(:last-child) {
          margin-right: 16px;
        }
      }
    }

    .appLinkForm {
      .appLinkButtons {
        display: flex;
        margin-bottom: 12px;

        input {
          border: 0;
          margin-right: 0;
        }

        button {
          border-radius: 0 3px 3px 0;
        }

        img {
          width: 154px;
          height: 40px;
        }
      }

      .message {
        margin-bottom: 12px;
        font-weight: bold;
      }
    }
  }

  .blueUpperFooter {
    background-color: $light-blue;
  }

  .lower {
    background-color: $light-blue;

    nav:not(:first-child) {
      margin-top: 16px;
    }

    .section {
      h2 {
        display: inline-block;
      }

      .citySubHeading {
        margin-left: 0.3em;
      }

      .locationSubHeading {
        display: inline-block;
        font-weight: normal;
        margin-left: 0.6em;

        &::before {
          content: "/";
          margin-right: 0.6em;
        }

        @media screen and (max-width: $desktop-min-width) {
          &:first-child {
            margin-left: 0;
          }

          &:first-child::before {
            content: "";
            margin: 0;
          }
        }

        @media screen and (max-width: $mobile-max-width) {
          margin-bottom: 0;

          &:last-of-type {
            margin-bottom: 0.5em;
          }
        }
      }

      .neighborhoodList ul {
        display: flex;
        flex-flow: column wrap;
        height: calc(42em - 42vw);
        min-height: 5em;
        max-height: 21em;

        li {
          padding-right: 0.5em;
          line-height: 2rem;
        }
      }

      .lists {
        display: flex;
        font-size: 0.8em;

        .listPairContainer {
          display: flex;
          flex-direction: column;
          width: 100%;

          ul {
            flex: 1;
            width: 100%;

            li {
              margin-bottom: 0.3em;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            @media screen and (min-width: $desktop-min-width) {
              width: 50%;
            }
          }

          &:not(:last-child) {
            padding-right: 10px;

            ul {
              @media screen and (min-width: $desktop-min-width) {
                padding-right: 0.5em;
              }
            }
          }

          @media screen and (min-width: $desktop-min-width) {
            flex-direction: row;
            width: 33%;
          }
        }

        @media screen and (min-width: $desktop-min-width) {
          font-size: 1em;
        }
      }
    }

    .footerBottomWrapper {
      align-items: flex-end;
      display: flex;
      border-bottom: 2px solid $white;
    }

    .footerBottomCities {
      flex: 2;
    }

    .newYorkTopCities {
      flex: 3;
    }

    .searchForOfficeSpace {
      flex: 2;

      ul {
        width: 33.333%;

        @media screen and (min-width: $desktop-min-width) {
          width: 25%;
        }
      }
    }

    .officeSpace {
      min-width: 25%;

      @media screen and (max-width: $mobile-max-width) {
        h2 {
          margin-bottom: 0;
        }
      }
    }

    @media screen and (max-width: $mobile-max-width) {
      .footerBottomWrapper {
        flex-direction: column;
      }

      .footerBottomCities,
      .footerBottomLegal {
        width: 100%;
      }
    }
  }
}

.footerBottomLegal {
  margin-bottom: 2rem;
  padding-top: 1em;
  opacity: 0.7;
  font-size: 13px;
}

.legalLinks {
  display: inline-block;
}

.legalLink,
.allRightsReserved,
.copyright {
  margin-right: 16px;
}

.submarkets {
  margin-top: 0.6rem;

  @media screen and (min-width: $desktop-min-width) {
    display: inline-block;
  }
}
