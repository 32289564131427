@import "/styles/variables.scss";

.button {
  font-size: 12px;
  padding: 10px;
  margin-left: 15px;
}

.banner {
  background-color: $dark-blue;
  color: $white;
  padding: 16px 24px;
  margin-bottom: 12px;
  text-align: center;
  z-index: $z-modal;

  .link {
    color: $white;
  }

  .dismissButton {
    right: 20px;
    color: $white;
    margin-top: -2px;
    cursor: pointer;
    font-size: 20px;
    position: absolute;
    outline: transparent;
    border-color: transparent;
    background-color: transparent;
  }
}

.dismissed {
  animation-name: animate;
  animation-duration: 1s;
}

@keyframes animate {
  from {
    opacity: 1;
    height: 60px;
    padding: 16px 24px;
    margin-bottom: 12px;
  }

  to {
    opacity: 0;
    height: 0;
    padding: 0;
    margin-bottom: 0;
  }
}
