@import "styles/variables.scss";

.topPostContainer {
  width: 100%;
  margin: 0 15px;
  box-shadow: none;
  transition: box-shadow ease-out 0.25s;

  &:hover {
    box-shadow: 0 0 12px 1px rgba(0, 0, 0, 0.2);
  }

  h3 {
    font-size: 36px;
    font-weight: bold;
    color: $black;
    margin: 0;
    padding: 20px 15px;
  }

  a {
    text-decoration: none;
    font-weight: bold;
    padding-bottom: 4px;
    border-bottom: 2px solid;
  }

  .topPostCardImage {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 242px;
  }
}

section.betterCompanyCultureSection {
  background-color: $off-white;

  :global(.inset) {
    background-color: $white;
    padding-top: 96px;
    padding-bottom: 96px;
  }

  @media screen and (max-width: $mobile-max-width) {
    padding-top: 0;
  }
}

.topPostCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1240px;
  margin: auto;
  padding-top: 40px;
}

.readMore {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
