@import "./variables.scss";

.container {
  background-color: $white;

  section {
    padding: 48px 24px;

    h1 {
      font-weight: normal;
    }

    h3 {
      letter-spacing: -1px;
    }

    h1:first-child,
    h2:first-child {
      font-weight: normal;
      margin-bottom: 24px;
      padding: 0 48px;
      font-size: 36px;
      line-height: 36px;
      letter-spacing: -1.5px;
      color: $black;
      text-align: center;

      @media screen and (max-width: $mobile-max-width) {
        font-size: 26px;
        letter-spacing: -1px;
        line-height: 30px;
        padding: 0;
      }
    }

    @media screen and (min-width: $desktop-min-width) {
      padding: 96px 48px;
    }
  }
}

.hideOverflowWrapper {
  width: 100%;
  overflow: hidden;
}

.welcomeWrapper {
  height: 80vh;
  max-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 48px;
  padding: 0 $body-padding-mobile $body-padding-mobile $body-padding-mobile;

  &::after {
    content: "";
    border: 6px solid $light-blue;
    position: absolute;
    top: 0;
    bottom: $body-padding-mobile;
    left: $body-padding-mobile;
    right: $body-padding-mobile;
    z-index: 1;

    @media screen and (min-width: $desktop-min-width) {
      top: 0;
      bottom: $body-padding;
      left: $body-padding;
      right: $body-padding;
      border-width: 10px;
    }
  }

  @media screen and (min-width: $desktop-min-width) {
    padding: 0 $body-padding $body-padding $body-padding;
    max-height: 800px;
    min-height: 400px;
  }
}

.welcomeHeaderContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  z-index: 2;

  .welcomeHeader {
    text-align: center;
    margin: -8vw 0 0;
    padding: 24px;
    line-height: 1.2;
    font-size: 36px;
    font-weight: bold;
    color: $black;

    .typedText {
      min-height: 1.2em;
    }

    @media screen and (min-width: $desktop-min-width) {
      font-size: 48px;
      line-height: 1.1;
    }
  }
}

.welcomeButton {
  display: block;
  margin: auto;
  width: 168px;
}

.welcomeHeroImage {
  position: absolute;
  bottom: -5%;
  margin: auto;
  width: 70%;
  min-width: 500px;
  object-position: center bottom;
}

.seoFullBleedBackground {
  background-color: #f4f4f4;
  width: 100%;
}

.valueProps {
  display: flex;
  justify-content: space-around;
  background-image:
    linear-gradient(
      to bottom,
      $white 0,
      $white 10.8em,
      $light-blue 10.8em,
      $light-blue 100%
    );

  @media screen and (max-width: $mobile-max-width) {
    flex-direction: column;
    background-image: none;

    > div {
      margin: 0 -$body-padding-mobile;
      background-image:
        linear-gradient(
          to bottom,
          $white 0,
          $white 9em,
          $light-blue 9em,
          $light-blue 100%
        );
    }
  }
}

.testimonialContainer {
  display: flex;
  height: 483px;
  max-width: 1126px;
  margin: auto;

  @media screen and (max-width: $mobile-max-width) {
    height: auto;
    flex-direction: column;
  }
}

.testimonialContent {
  padding: 64px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;

  @media screen and (min-width: $desktop-min-width) {
    padding: 64px 80px;
    max-width: 446px;
    min-width: 328px;
  }

  &::before {
    content: "";
    border: 6px solid $light-blue;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;

    @media screen and (min-width: $desktop-min-width) {
      border-width: 10px;
    }
  }

  .ollieTestimonialLink {
    z-index: 10;
    width: 91px;
    font-weight: bold;
    margin-top: 30px;
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 2px;
    text-decoration: none;
    letter-spacing: -0.5px;
    border-bottom: 2px solid #ff5050;
  }
}

.testimonialQuotation::before {
  content: "\201c";
  display: block;
  font-size: 64px;
  margin-bottom: -20px;
  line-height: 1;

  @media screen and (min-width: $desktop-min-width) {
    font-size: 72px;
  }
}

.testimonialTestifier {
  max-width: 188px;
  margin-top: 20px;
}

.testimonialImage {
  margin-left: -$body-padding-mobile;
  margin-right: -$body-padding-mobile;
  height: 380px;
  transform: translateY(-32px);
  box-shadow: 0 0 6px #777;

  @media screen and (min-width: $desktop-min-width) {
    transform: translateX(-40px) translateY(40px);
    width: 100%;
    max-width: 724px;
    height: 100%;
  }
}

.testimonialLogos {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  list-style: none;

  @media screen and (min-width: $desktop-min-width) {
    margin-top: 60px;
  }

  li {
    width: 90px;
    padding: 20px 5px;

    @media screen and (min-width: $desktop-min-width) {
      width: 140px;
    }
  }
}

section.notYourOrdinaryBrokerSection {
  overflow: hidden;
  background-color: $off-white;
  padding-left: 0;
  padding-right: 0;

  @media screen and (max-width: $mobile-max-width) {
    padding-top: 20px;
    padding-bottom: 0;
  }
}

.notYourOrdinaryBrokerContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  @media screen and (max-width: $mobile-max-width) {
    flex-direction: column-reverse;
    justify-content: center;
  }

  button {
    margin: 0;
  }

  .iPhoneImage,
  .laptopImage {
    background-size: cover;
    background-repeat: no-repeat;
    transition: transform 1s;

    &.animated {
      transform: translateX(0);

      @media screen and (max-width: $mobile-max-width) {
        transform: translateY(0);
      }
    }
  }

  .iPhoneImage {
    height: 492px;
    background-position-x: -40px;
    transform: translateX(-600px);

    @media screen and (max-width: $mobile-max-width) {
      width: 221px;
      height: 174px;
      object-fit: cover;
      object-position: 100% 0;
      transform: translateY(230px);
      margin: auto;
    }
  }

  .laptopImage {
    // background-image: url(./assets/laptop.png);
    width: 720px;
    height: 501px;
    transform: translateX(1100px);

    @media screen and (max-width: $mobile-max-width) {
      display: none;
    }
  }
}

.topPostCardContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1240px;
  margin: auto;
  padding-top: 40px;
}
